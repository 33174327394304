.experienceCard {
	position: relative;
}

.experienceCard__line {
	position: absolute;
	content: "";
	left: 0;
	top: 50%;
	transform: translateX(-4rem) translateY(-50%);
	width: 4rem;
	transform-origin: 0% 50%;
	transition: 300ms ease-in-out transform;
}
