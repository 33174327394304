.button {
	display: inline-block;
	position: relative;
	outline: none;
	border-color: var(--secondary);
	transition: color 300ms ease-in-out,
		border 300ms ease-in-out;

	&:disabled {
		opacity: 0.5;
		pointer-events: none;
	}

	&:hover,
	&:active,
	&:focus {
		.button__overlay {
			transform: translateX(0%) skewX(-45deg);
		}

		svg {
			transform: translateX(0.5rem);
		}
	}
}

.button__overlay {
	position: absolute;
	top: 0;
	left: -1.1rem;
	width: calc(100% + 2.4rem);
	height: 100%;
	transform: translateX(100%) skewX(-45deg);
	transition: ease-in-out 300ms all;
	z-index: 1;
}

.button__content {
	position: relative;
	z-index: 2;
	display: flex;
	align-items: center;

	svg {
		margin-left: 0.5rem;
		transition: ease-in-out 300ms transform;
	}
}
