.skillCard {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: 10rem;
	overflow: hidden;
}

.skillCard__label {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 2rem;
	width: 100%;
	box-sizing: border-box;

	svg {
		position: absolute;
		left: 0.5rem;
		width: 2rem;
		height: 1.25rem;
	}
}

.skillCard__body {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: auto;
	flex-grow: 1;
	padding: 1rem 0;
}

.skillCard__circle {
	position: relative;
	width: 8rem;
	height: 8rem;

	svg {
		position: relative;
		width: 8rem;
		height: 8rem;

		circle {
			width: 100%;
			height: 100%;
			fill: transparent;
			transform-origin: center;
			transform-box: fill-box;

			stroke-width: 2;
			transform: translate(0.25rem, 0.25rem)
				rotate(-90deg);

			&:nth-child(1) {
				stroke: var(--primary);
			}

			&:nth-child(2) {
				stroke-dasharray: 380;
				stroke-dashoffset: calc(
					380 - (380 * var(--num)) / 100
				);
				stroke: var(--progressColor);
			}
		}
	}
}

.skill__card__dot {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 50%;

	&--inner {
		position: absolute;
		top: 1rem;
		left: 50%;
		width: 0.8rem;
		height: 0.8rem;
		border-radius: 50%;
		background-color: var(--progressColor);
		transform: translate(-50%, -1.1rem);
		box-shadow: 0 0 4px 0 var(--progressColor);
	}
}
