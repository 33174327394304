:root {
	--navigationGap: 0.6rem;
}

.navigation {
	margin-top: var(--navigationGap);
}

.navigation__links {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.navigation__item {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 1;
	cursor: pointer;
	border-radius: 50%;

	&.active {
		.navigation__item--label {
			transform: translateY(50%);
			opacity: 0;
		}
		.navigation__item--icon {
			transform: translateY(calc(-50% - 1.4rem));

			@media screen and (max-width: 767px) {
				transform: translateY(calc(-50% - 0.9rem));
			}
		}
	}
}

.navigation__item--icon {
	transition: transform 300ms ease-in-out;

	@media screen and (max-width: 767px) {
		flex-grow: 1;
	}

	svg {
		width: 2rem;
		height: 2rem;

		@media screen and (max-width: 767px) {
			width: 1.6rem;
			height: 1.6rem;
		}
	}
}

.navigation__item--label {
	transition: opacity 300ms ease-in-out,
		transform 300ms ease-in-out;
	transform: translateY(0%);
	margin-top: 0.4rem;

	@media screen and (max-width: 767px) {
		flex-grow: 1;
		margin-top: 0rem;
	}
}

.navigation__indicator {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	top: calc(-50% - 0.3rem);
	border-radius: 50%;
	transition: left 300ms ease-in-out;

	@media screen and (max-width: 767px) {
		top: calc(-50% + 0.2rem);
	}

	.navigation__indicator__corner {
		position: absolute;
		content: "";
		width: 1.4rem;
		height: 1.4rem;
		background-color: transparent;
		transform-origin: center;
	}

	.navigation__indicator__corner--1 {
		left: -1.5rem;
		transform: translateY(1rem);
		border-top-right-radius: 1.4rem;
		box-shadow: 0.1rem -0.5rem 0 var(--primary);

		@media screen and (max-width: 767px) {
			left: -1.4rem;
		}
	}

	.navigation__indicator__corner--2 {
		right: -1.5rem;
		transform: translateY(1rem);
		border-top-left-radius: 1.4rem;
		box-shadow: -0.1rem -0.5rem 0 var(--primary);

		@media screen and (max-width: 767px) {
			right: -1.4rem;
		}
	}

	.navigation__indicator__corner--3 {
		left: -1.5rem;
		transform: translateY(-1rem);
		border-bottom-right-radius: 1.4rem;
		box-shadow: 0.1rem 0.5rem 0 var(--primary);
	}

	.navigation__indicator__corner--4 {
		right: -1.5rem;
		transform: translateY(-1rem);
		border-bottom-left-radius: 1.4rem;
		box-shadow: -0.1rem 0.5rem 0 var(--primary);
	}
}
