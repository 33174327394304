.switch {
	height: 40px;
	position: relative;
}

.switch__input {
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	height: 1px;
	width: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
}

.switch__label {
	position: relative;
	display: inline-block;
	width: 80px;
	height: 40px;
	border: 2px solid #d1d5db;
	border-radius: 9999px;
	cursor: pointer;
	transition: all 0.4s
		cubic-bezier(0.46, 0.03, 0.52, 0.96);
}

.switch__indicator {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) translateX(72%);
	display: block;
	width: 30px;
	height: 30px;
	background-color: rgb(255, 217, 0);
	border-radius: 9999px;
	box-shadow: 0 0 4px 1px #ffee00;

	&::before,
	&::after {
		display: none;
	}
}

.switch__decoration {
	position: absolute;
	top: 65%;
	left: 50%;
	display: block;
	background-color: white;
	top: 50%;
	transform: translate(0%, -50%);
	animation: cloud 8s linear infinite;
	width: 20px;
	height: 20px;

	&::before,
	&::after {
		position: absolute;
		display: block;
		content: "";
		width: 3px;
		height: 3px;
		background-color: white;
		border-radius: 9999px;
	}

	&::before {
		width: 10px;
		height: 10px;
		top: auto;
		bottom: 0;
		left: -10px;
		animation: none;
	}

	&::after {
		width: 15px;
		height: 15px;
		top: auto;
		bottom: 0;
		left: 16px;
		animation: none;
	}

	&,
	&::before,
	&::after {
		border-radius: 9999px 9999px 0 0;
	}

	&::after {
		border-bottom-right-radius: 9999px;
	}
}

@keyframes twinkle {
	50% {
		opacity: 0.6;
	}
}

.switch__indicator {
	&,
	&::before,
	&::after {
		transition: all 0.4s
			cubic-bezier(0.46, 0.03, 0.52, 0.96);
	}
}

.switch__input:checked + .switch__label {
	background-color: var(--primary);
	border: 2px solid var(--secondary);

	.switch__indicator {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) translateX(-72%);
		display: block;
		width: 30px;
		height: 30px;
		background-color: rgba(white, 0.05);
		box-shadow: 7px 0px 0 0 rgba(white, 1) inset;
	}

	.switch__decoration {
		position: absolute;
		top: 65%;
		left: 50%;
		display: block;
		width: 2px;
		height: 2px;
		background-color: white;
		border-radius: 9999px;
		animation: twinkle 1.5s infinite -0.8s;

		&::before,
		&::after {
			position: absolute;
			display: block;
			content: "";
			width: 3px;
			height: 3px;
			background-color: white;
			border-radius: 9999px;
		}

		&::before {
			top: -20px;
			left: 10px;
			opacity: 1;
			animation: twinkle 1.5s infinite 0.2s;
		}

		&::after {
			top: -7px;
			left: 30px;
			animation: twinkle 1.5s infinite -0.4s;
		}
	}
}

@keyframes cloud {
	0% {
		transform: translate(0%, -50%);
	}
	50% {
		transform: translate(-50%, -50%);
	}
	100% {
		transform: translate(0%, -50%);
	}
}
