@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
	scroll-behavior: smooth;
}

body {
	margin: 0;
	font-family: Inter, Arial, Helvetica, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

:root {
	div[data-mode="dark"] {
		--primary: #0c0a09;
		--secondary: #6b7280;
		--iconText: #f3f4f6;
	}
}

div[data-mode="light"] {
	--primary: #f3f4f6;
	--secondary: #d1d5db;
	--iconText: #0c0a09;
}
